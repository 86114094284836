import React from "react";
import "./../../App.css";
import { CarouselProvider, Slider, Slide, ImageWithZoom, Image, ButtonBack, ButtonNext, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import screens from "./../../images/screens";

export const Screenshots = () => {
  return (
    <div className="carousel">
      <CarouselProvider
        visibleSlides={3}
        naturalSlideWidth={1000}
        naturalSlideHeight={1979}
        totalSlides={8}
        isPlaying
        interval={2500}
        infinite
      >
        <Slider>
        {screens.map((image, i) => {
          return (
            <Slide key={i} index={i}>
              <ImageWithZoom src={image} srcZoomed={image} />
            </Slide>
          );
        })}
        </Slider>
        {/* <ButtonBack className="carouselButton">Back</ButtonBack>
        <ButtonNext className="carouselButton">Next</ButtonNext> */}
      </CarouselProvider>
    </div>
  );
};
