import React from "react";
import "./../App.css";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import withWidth from "@material-ui/core/withWidth";
import Container from "@material-ui/core/Container";
import { Stores } from "./common/Stores";

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#424242",
    fontFamily: "Montserrat",
    fontWeight: 700,
    //fontSize: 16,
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab {...props} />);

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 10,
      width: "100%",
      backgroundColor: "#81c784",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const SupportService = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="md">
      <div className="center">
        <span className="text">Solvergy: </span>
        <span className="textGrey">Buildings</span>
      </div>
      <br />
      <div className="center">
        <span className="header">Help documentation</span>
      </div>
      <br />
      <Box boxShadow={4}>
        <div className={classes.root}>
          <StyledTabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            className={classes.tabs}
          >
            <StyledTab label="About app" />
            <StyledTab label="Constructor" />
            <StyledTab label="Express" />
            <StyledTab label="Analytics" />
            <StyledTab label="Templates" />
            <StyledTab label="Contact us" />
          </StyledTabs>
          <TabPanel value={value} index={0}>
            Solvergy: Buildings application is designed to quickly analyze the
            energy status of buildings that consume energy for heating and
            maintain comfortable indoor conditions. The application allows you
            to determine the energy class of the building, the average and
            maximum consumed thermal power of the building, as well as the level
            of thermal energy consumption by the building throughout the year.
            In addition to the “Constructor” mode, which allows you to create a
            model of building walling in a graphical editor using appropriate
            templates for the composition of building wallings, there is an
            “Express” mode, which, using artificial intelligence, allows you to
            evaluate the energy class and characteristics of a building within a
            few minutes. The calculations are based on European regulatory
            documents and standards for calculating the thermal characteristics
            of a building.
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div class="youtubeContainer">
              <iframe
                src="https://www.youtube.com/embed/-rbdb5POfcc"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                className="video"
              ></iframe>
            </div>
            <br/>
            The “Constructor” mode allows you to create a model of the building
            walling using the structure templates of the building walling. The
            user is invited to fill out the necessary forms to create a draft
            model of the building walling at 5 stages:
            <p>
              - General information about the project. The user must indicate
              the name of the project, the number of storeys of the building and
              its location on the map.
            </p>
            <p>
              - Characteristics of the walls of the building (floor graphic
              editor). The user draws the layout of the external walls of the
              building to assess the shape and dimensions of the building, and
              then indicates the composition of the walls, which is determined
              by the corresponding templates that can be created in the
              "Templates" section. Also, the user can indicate the presence of
              doors and windows in the wall.
            </p>
            <p>
              - Characteristics of the floor of the building. The user indicates
              the composition of the floor using templates that can be created
              in the "Templates" section, as well as a comfortable temperature
              on the floor.
            </p>
            <p>
              - Roof specifications. The user indicates the composition of the
              floor using templates that can be created in the "Templates"
              section, as well as the characteristics of the roof.
            </p>
            <p>
              - Report settings. The user has the opportunity to choose the
              possibility of obtaining a detailed report, which provides the
              opportunity to study monthly and daily data.
            </p>
            <p>
              After completing all 5 steps and clicking the "Calculate" button,
              a calculation will be made, the results of which will be displayed
              on the screen of the "Report" tab, as well as in the "Analytics"
              column.
            </p>
          </TabPanel>
          <TabPanel value={value} index={2}>
          <div class="youtubeContainer">
              <iframe
                src="https://www.youtube.com/embed/GeKhi_1q4Ho"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                className="video"
              ></iframe>
            </div>
            <br/>
            The Express mode allows you to evaluate the energy class and
            building characteristics within a few minutes without using the
            Constructor mode. The process of using the Express mode is to
            provide the following data:
            <p>- Storeys of the building.</p>
            <p>
              - Top view of the building on the map. When indicating the
              building on the map, it is necessary that the entire outline of
              the building be in the field of visibility of the map, in its
              center. Otherwise, the calculations may be incorrect.
            </p>
            <p>
              - Photos of the appearance of buildings. The user must upload
              photos (or take a photo) of the appearance of the buildings in an
              amount of more than 3 pieces. The more photos you take, the better
              results you can expect.
            </p>
            <p>
              After completing all the steps and clicking the "Calculate"
              button, a calculation will be made, the results of which will be
              displayed on the screen of the "Report" tab, as well as in the
              "Analytics" column.
            </p>
          </TabPanel>
          <TabPanel value={value} index={3}>
            In the "Analytics" section, the results of calculations of projects
            completed in the "Designer" or "Express" modes are presented. The
            user has the opportunity to choose the opportunity to receive a
            detailed report at the project development stage, which provides the
            opportunity to study monthly and daily data.
          </TabPanel>
          <TabPanel value={value} index={4}>
          <div class="youtubeContainer">
              <iframe
                src="https://www.youtube.com/embed/7LhVug7WrOQ"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                className="video"
              ></iframe>
            </div>
            <br/>
            In the “Templates” tab, user can create templates for the
            composition of the building walling (walls, floor or roof) that will
            be used when creating projects in the “Constructor” mode. The
            essence of creating templates is to add layers of materials that
            make up the enclosing structure, which are characterized by the
            thickness of the layer and its type. The user will be prompted to
            select the type of material from the general classification of
            building materials according to regulatory documents.
          </TabPanel>
          <TabPanel value={value} index={5}>
            If you have any questions about Solvergy: Buildings application,
            please send email message to <ins>dmitriy.qua@gmail.com</ins>.
          </TabPanel>
        </div>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: "white",
    display: "flex",
    height: 524,
    margin: "auto",
    //width: 500,
    maxWidth: 850,
    fontFamily: "Montserrat",
  },
  tabs: {
    minWidth: 150,
    borderRight: `1px solid #e0e0e0`,
  },
}));

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      className="tabsContent"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <span className="helpText">{children}</span>
        </Box>
      )}
    </div>
  );
}
