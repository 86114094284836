import React from 'react';
import './../App.css';
import { Stores } from './common/Stores';
import { Screenshots } from './common/Screenshots';
import { Link } from 'react-router-dom';
const logo = require("./../images/logo-white.png")

export const Main = () => {
  return (
    <div className="App">
      <div className="center">
        <img src={logo} className="logo"></img>
        <br/>
        <span className="text">Solvergy: </span><span className="textGrey">Buildings</span>
        <p/>
        <span className="description">Innovative tool for calculating consumption and energy efficiency of a building.</span>
        <br/>
        <Screenshots/>

        <Stores/>
        <p></p>
        <Link to="/support" style={{textDecoration: "none"}}>
          <div className="supportButton">
            <span className="supportText">Support</span>
          </div>
        </Link>
        {/* <img src={img} className="logo" height="70"></img> */}
      </div>
    </div>
  );
}

export default Main;