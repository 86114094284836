import React from 'react';
import './../../App.css';

const googlePlay = require("./../../images/google-play.png")
const appleStore = require("./../../images/apple-store.png")

export const Stores = () => {
    return (
    <div className="center">
    <a href="https://play.google.com/store/apps/details?id=com.solvergy.buildings"><img src={googlePlay} className="googlePlay"/></a>
    <a href="https://apps.apple.com/us/app/id1518494813"><img src={appleStore} className="appleStore"></img></a>
  </div>
  )
}
