import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Main} from "./components/Main";
import {SupportService} from './components/SupportService'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/support">
          <SupportService />
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
