import screen1 from './screens/01.png'
import screen2 from './screens/02.png'
import screen3 from './screens/03.png'
import screen4 from './screens/04.png'
import screen5 from './screens/05.png'
import screen6 from './screens/06.png'
import screen7 from './screens/07.png'
import screen8 from './screens/08.png'

export default [
    screen1,
    screen2,
    screen3,
    screen4,
    screen5,
    screen6,
    screen7,
    screen8
]